<template>
    <div>
        <div>
            <form @submit.prevent="create">
                <div class="modal-row2 modal-height-max" id="myTabContent">
                    <div class="tab-pane" id="template">
                        <div class="modal-body">
                            <div class="row mt-negative-1">
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 overflow labels-container"
                                     style="overflow-x: hidden; overflow-y: auto">
                                    <div class="tags-block">
                                        <h5>Tags</h5>
                                        <div class="lablesContainerScroll">
                                            <div class="tags" v-for="(category, key) in tags.categories_raiz"
                                                 :key="key">
                                                <a href="#" @click="alterOpenRaiz(key)">
                                                    <i class="icon-label" :class="colorTag(key)"></i> {{ category }}
                                                    <i class="icon-expand_more" v-if="!openRaiz[key]?.open"></i>
                                                    <i class="icon-expand_less font-weight-bold"
                                                       v-if="openRaiz[key]?.open"></i>
                                                </a>
                                                <div class="ml-2" v-show="openRaiz[key]?.open">
                                                    <div class="tags " v-for="(categoryc, keyc) in tags.items_raiz[key]"
                                                         :key="keyc"
                                                         :class="categoryc.open? 'border-bottom': ''">
                                                        <a href="#" @click="categoryc.open = !categoryc.open">
                                                            <i class="icon-label"></i> {{ keyc }}
                                                            <i class="icon-expand_more" v-if="!categoryc.open"></i>
                                                            <i class="icon-expand_less font-weight-bold"
                                                               v-if="categoryc.open"></i>
                                                        </a>
                                                        <div v-show="categoryc.open">
                                                            <div class="d-flex flex-row container align-items-start flex-wrap">
                                                                <div v-for=" (category) in categoryc"
                                                                     :key="category.id">
                                                                    <div class="badge badge-pill badge-light mb-1 mt-1 mr-1"
                                                                         style="font-size: 0.73rem; white-space: normal; cursor: pointer"
                                                                         @click="copyText(`${key}${category.name_tag}`, category.traduction_tag)"
                                                                         :id="`${key}${category.name_tag}`">
                                                                        {{ category.text }}
                                                                        <i title="copiar" class="icon-copy"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tags" v-for="(category, key) in tags.categories" :key="key"
                                                 :class="tags.items[key].open? 'border-bottom': ''">
                                                <a href="#" @click="tags.items[key].open = !tags.items[key].open">
                                                    <i class="icon-label" :class="colorTag(key)"></i> {{ category }}
                                                    <i class="icon-expand_more" v-if="!tags.items[key].open"></i>
                                                    <i class="icon-expand_less font-weight-bold"
                                                       v-if="tags.items[key].open"></i>
                                                </a>
                                                <div v-show="tags.items[key].open">
                                                    <div class="d-flex flex-row container align-items-start flex-wrap">
                                                        <div v-for=" (category) in tags.items[key]" :key="category.id">
                                                            <div class="badge badge-pill badge-light mb-1 mt-1 mr-1"
                                                                 style="font-size: 0.73rem; white-space: normal; cursor: pointer"
                                                                 @click="copyText(`${key}${category.name_tag}`, category.traduction_tag)"
                                                                 :id="`${key}${category.name_tag}`">{{ category.text }}
                                                                <i title="copiar" class="icon-copy"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8 mt-3">
                                    <div class="row gutters">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group">
                                                <label for="name" class="required">{{
                                                        t('PROPOSAL.TEMPLATE_NAME')
                                                    }}: </label>
                                                <input type="text" id="name" name="name" v-model="proposal.name"
                                                       class="form-control"
                                                       placeholder="Nome">
                                                <p class="validation" v-if="requiredName">
                                                    {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row gutters">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="form-group">
                                                <label class="required">{{ t('PROPOSAL.TEMPLATE') }}: </label>
                                                <editor :init="configTinyMce" v-model="proposal.text"
                                                        tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                                ></editor>
                                                <p class="validation" v-if="requiredText">
                                                    {{ t('GENERAL.MSG.REQUIRED_FIELD') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <div>
                        <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                    </div>
                    <div>
                        <button data-cy="client_add_close" class="btn btn-secondary mr-2" data-dismiss="modal"
                                type="button">
                            {{ t('ACTIONS.CLOSE') }}
                        </button>
                        <button data-cy="client_add_save" class="btn btn-primary" type="submit">
                            {{ t('ACTIONS.SAVE') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Editor from '@tinymce/tinymce-vue';
import Proposal from "../../services/Proposal";
import Tags from "../../services/Tags";

export default {
    name: "createTemplateProposalComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    props: {
        itenIndex: {
            type: Object,
            required: true
        }
    },
    watch: {
        itenIndex: function () {
            this.proposal = this.itenIndex;
            this.listTags();
        }
    },
    components: {
        Editor,
    },
    data() {
        return {
            proposal: {},
            configTinyMce: {
                license_key: 'gpl',
                selector: 'textarea#open-source-plugins',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                link_list: [
                    {title: 'My page 1', value: 'https://www.tiny.cloud'},
                    {title: 'My page 2', value: 'http://www.moxiecode.com'}
                ],
                image_list: [
                    {title: 'My page 1', value: 'https://www.tiny.cloud'},
                    {title: 'My page 2', value: 'http://www.moxiecode.com'}
                ],
                image_class_list: [
                    {title: 'None', value: ''},
                    {title: 'Some class', value: 'class-name'}
                ],
                importcss_append: true,
                file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                        callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                        callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                        callback('movie.mp4', {source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg'});
                    }
                },
                templates: [
                    {
                        title: 'New Table',
                        description: 'creates a new table',
                        content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
                    },
                    {
                        title: 'Starting my story',
                        description: 'A cure for writers block',
                        content: 'Once upon a time...'
                    },
                    {
                        title: 'New list with dates',
                        description: 'New List with dates',
                        content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
                    }
                ],
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: 'oxide',
                content_css: 'default',
                language: 'pt_BR',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            requiredName: false,
            requiredText: false,
            openRaiz: [],
            tags: {items: [], categories: [], categories_raiz: [], items_raiz: []}
        }
    },
    mounted() {
        this.listTags();
        this.proposal = this.itenIndex;
    },
    methods: {
        alterOpenRaiz(key) {
            if (this.openRaiz[key] === undefined) this.openRaiz[key] = {};
            this.openRaiz[key].open = !this.openRaiz[key]?.open
        },
        copyText(id, textTag) {
            navigator.clipboard.writeText(textTag);
        },
        colorTag(key) {
            if (key > 6) key = (key % 6) - 1;
            if (key === 0) return 'text-primary';
            if (key === 1) return 'text-secondary';
            if (key === 2) return 'text-info';
            if (key === 3) return 'text-warning';
            if (key === 4) return 'text-success';
            if (key === 5) return 'text-danger';
            if (key === 6) return 'text-success';
            if (key) return 'text-primary';
        },
        listTags() {
            this.$store.commit('changeLoading', true);
            Tags.list().then(resp => {
                resp.data.forEach(item => {
                    if (item.categoria_raiz && !this.tags.categories_raiz.includes(item.categoria_raiz)) {
                        this.tags.categories_raiz.push(item.categoria_raiz);
                    } else if (!item.categoria_raiz) {
                        if (!this.tags.categories.includes(item.category)) {
                            this.tags.categories.push(item.category);
                        }
                    }
                });
                this.tags.categories.forEach((category, key) => {
                    this.tags.items[key] = []
                    resp.data.forEach(item => {
                        if (item.category === category) {
                            this.tags.items[key].push(item);
                        }
                    });
                })
                this.tags.categories_raiz.forEach((category, key) => {
                    this.tags.items_raiz[key] = [];
                    resp.data.forEach(item => {
                        if (item.categoria_raiz === category) {
                            this.tags.items_raiz[key].push(item);
                        }
                    });
                })
                if (this.tags.items_raiz?.length) {
                    this.tags.items_raiz.forEach((item, key) => {
                        let aux = {};
                        item?.forEach(i => {
                            if (!aux[i.category]) {
                                if (aux[i.category] === undefined) aux[i.category] = [];
                                aux[i.category].push(i);
                            } else {
                                aux[i.category].push(i);
                            }
                        })
                        this.tags.items_raiz[key] = aux;
                    })
                }
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        create() {
            let invalid = false;
            if (!this.proposal.name) {
                this.requiredName = true;
                document.getElementById('name').classList.add('is-invalid');
                invalid = true;
            } else {
                this.requiredName = false;
                document.getElementById('name').classList.remove('is-invalid');
                invalid = false
            }
            if (!this.proposal.text) {
                this.requiredText = true;
                invalid = true;
            } else this.requiredText = false;
            if (invalid) return;

            Proposal.createTemplate(this.proposal).then(() => {
                this.toast.success(this.t('PROPOSAL.TEMPLATE_CREATED'));
                this.$emit('created');
                document.getElementById('closeX').click();
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            })
        },
        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.mt-negative-1 {
    margin-top: -1.2%;
}

div::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
}
</style>